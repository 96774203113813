<template>
	<div class="evaluate">
		<div class="boxEval" >
			<div v-for="(items,index) in ordersList.products" class="goods">
				<van-card :price="items.price" :desc="items.attr" :title="items.name" :thumb="imageUrl + items.image"
					:num="items.count">
				</van-card>
				<div class="pingjia">
					商品评价：
					<van-rate v-model="items.star_product" @change="shopEval" />
				</div>
				<div class="pingjia">
					快递评价：
					<van-rate v-model="items.star_postman" @change="expreEval" />
				</div>
				<div class="pingjia">
					店铺评价：
					<van-rate v-model="items.star_store" @change="storeEval" />
				</div>
				
				<div class="miaoshu">
					<textarea v-model="items.content" placeholder="从多角度评价宝贝,可以帮助更多想买的人"></textarea>
				</div>
				<div class="uploadImg">
					<p>上传图片</p>
					<van-uploader :before-delete="afterDelete" :after-read="afterRead" :name="index" v-model="items.images"
						multiple :max-count="5" /> 
				</div>
				<div class="checked">
					<van-field name="is_appointment" label="是否匿名">
						<template #input class="business_location">
							<van-switch v-model="items.is_anonym" :active-value="1" :inactive-value="0" size="20"
								@click="businessChange(is_business)" />
						</template>
					</van-field>
				</div>
				
			</div>
			<div class="btnCon">
				<button @click="onSubmit()">提交</button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getOrderDetail,
		commentAdd,
		uploadImage
	} from '@/api/order'
	export default {
		data() {
			return {
				imageUrl: this.url.imageUrl,
				ordersList: '',
				formdata: {
					images: []
				},
				uploads: [],
				rep: '',
				// commObj: {
				// 	pid: '',
				// 	union: '',
				// 	star_product: '',
				// 	star_postman: '',
				// 	star_store: '',
				// 	images: '',
				// 	content: '',
				// 	is_anonym: ''
				// },
				commArr: []
			}
		},
		mounted() {
			this.getOrderDetail()
			console.log(this.orderDetail)
		},
		methods: {
			getOrderDetail() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				let params = {}
				params.id = this.$route.query.id
				getOrderDetail(params).then((res) => {
					console.log(res)
					this.ordersList = res.data
					for(var i = 0; i <this.ordersList.products.length;i++){
						this.ordersList.products[i].star_product = 0
						this.ordersList.products[i].star_postman = 0
						this.ordersList.products[i].star_store = 0
						this.ordersList.products[i].images = []
						this.ordersList.products[i].uploads = []
						this.ordersList.products[i].content = null
						this.ordersList.products[i].is_anonym = 0
					}
					
					this.$toast.clear()
				})
			},
			shopEval(value) {
				console.log('当前值：' + value);
			},
			expreEval(values) {
				console.log('当前值：' + values);
			},
			storeEval(valuess) {
				console.log('当前值：' + valuess);
			},
			afterDelete(file, detail) {
				console.log(detail.name)
				// switch (detail.name) {
				// 	case 'index':
						this.ordersList.products[detail.name].uploads.splice(detail.index, 1)
						this.ordersList.products[detail.name].images.splice(detail.index, 1)
				// 		break
				// }
				console.log(this.ordersList.products[detail.name].uploads)
			},
			afterRead(file,detail) {
				console.log(detail.name)
				console.log(file.file)
				// console.log(index)
				const formData = new FormData();
				formData.append('file', file.file)
				console.log(formData)
				uploadImage(formData).then((res) => {
					console.log(res.data.path)
					this.ordersList.products[detail.name].uploads.push(res.data.path)
				})
			},
			// 
			businessChange(is_business) {
				console.log(is_business)
			},
			// 
			onSubmit() {
				console.log(this.ordersList.products)
				// this.formdata.images = this.uploads.toString()
				// this.rep = this.formdata.images.replace(/,/, ',')
				this.commArr = [];
				for(var i = 0; i < this.ordersList.products.length;i++){
					console.log(this.ordersList.products[i])
					let commObj = {}
					let list = this.ordersList.products[i]
					commObj.pid = list.pid
					commObj.union = list.union
					commObj.star_product = list.star_product
					commObj.star_postman = list.star_postman
					commObj.star_store = list.star_store
					commObj.images = list.uploads.toString()
					commObj.content =  list.content
					commObj.is_anonym =  list.is_anonym
					this.commArr.push(commObj)
				}
				let params = {
					oid: this.ordersList.id,
					comment: this.commArr
				}
				commentAdd(params).then((res) => {
					console.log(res)
					this.$toast(res.msg);
					setTimeout(()=>{
						this.$router.push({
							path: '/myOrders'
						})
					},1000)
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.evaluate {
		display: flex;
		align-items: center;
		justify-content: center;

		.boxEval {
			margin-top: 15px;
			border-radius: 8px;
			width: 90%;
			background-color: #FFFFFF;

			.pingjia {
				padding: 8px 16px;
				display: flex;
				align-items: center;
			}

			.miaoshu {
				width: 90%;
				height: 150px;
				padding: 8px 16px;

				textarea {
					width: 100%;
					height: 150px;
					resize: none;
					padding: 3px;
				}
			}

			.uploadImg {
				padding: 8px 16px;
			}

			.checked {
				padding: 8px 16px;
				display: flex;
				align-items: center;
			}

			.btnCon {
				padding: 8px 16px;
				width: 100%;
				height: 35px;

				button {
					width: 90%;
					height: 100%;
					background-color: #ee0a24;
					border: solid 1px #ee0a24;
					color: #FFFFFF;
					border-radius: 17px;
				}
			}
		}
	}
</style>
